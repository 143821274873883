import React from "react";
import "../Css/Courses.css";
import { Card } from "./UI/Card";

const Courses = () => {
  return (
    <div className="courses-container">
      <div className="courses-main">
        <div className="courses-heading">
          <h1>Featured Courses</h1>
          <p>
            Turn your ambitions into reality with our coding-centric courses,
            delivering hands-on, intensive learning experiences.
          </p>
          {/* <div>
            <button className="course-btn">All</button>
            <button className="course-btn-inactive">PG Certifications</button>
            <button className="course-btn-inactive">Master's Degree</button>
          </div> */}
        </div>
        <div className="courses-card">
          <Card
            heading="Certification in MERN Stack Development"
            text="Achieve job readiness in MERN Stack development within 6 months. Engage with the premier tech community in India. Secure your spot today and aim for jobs surpassing 5 LPA"
            duration={4}
          />
          <Card
            heading="Certification in the React.js Development"
            text="Unlock your potential as a React.js expert in just 8 weeks. Join India's largest tech community for unparalleled support. Secure your seat today and pave your way to roles offering 5 LPA and beyond."
            duration={2.5}

          />
        </div>
      </div>
    </div>
  );
};

export default Courses;
