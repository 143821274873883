// RunningBanner.jsx
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import "../../Css/RunningBanner.css";
import EnquiryForm from "./EnquiryForm"; // Import the EnquiryForm
import ImageForm from "./ImageForm";
import newbatch from "../../almabetter resoures/Newbatch.jpg";

const RunningBanner = () => {
  const [isEnquiryFormVisible, setEnquiryFormVisible] = useState(false);
  const [isImageFormVisible, setImageFormVisible] = useState(false);

  const handleCloseEnquiryForm = () => {
    setEnquiryFormVisible(false);
  };

  const handleCloseImageForm = () => {
    setImageFormVisible(false);
  };

  return (
    <>
      <Marquee
        className="main-banner"
        speed={50}
        gradient={false}
        pauseOnHover={true}
      >
        <div className="running-text">
          Demo sessions Monday to Saturday &nbsp;&nbsp;Contact:+91 7219777599
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            className="pulse-button"
            onClick={(e) => {
              e.stopPropagation();
              setEnquiryFormVisible(true);
            }}
          >
            Register
          </button>
          <a
            style={{ marginLeft: "20px", color: "#ed2d16" }}
            // className="pulse-button"
            onClick={(e) => {
              e.stopPropagation();
              setImageFormVisible(true);
            }}
          >
            Upcoming Batches
          </a>
        </div>
      </Marquee>

      {/* Enquiry Form Modal */}
      {isEnquiryFormVisible && (
        <EnquiryForm
          isVisible={isEnquiryFormVisible}
          onClose={handleCloseEnquiryForm}
        />
      )}

      {/* Image Form Modal */}
      {isImageFormVisible && (
        <ImageForm
          isVisible={isImageFormVisible}
          onClose={handleCloseImageForm}
          imageUrl={newbatch}
        />
      )}
    </>
  );
};

export default RunningBanner;
