import React from "react";
import photo from "../almabetter resoures/Transform.png";
import "../Css/Transform.css";
export const Transform = () => {
  return (
    <div className="transform-container">
      <div className="transform-desc">
        <h1>
          Fostering learning journeys that drive career progression in tech.
        </h1>
        <p style={{fontWeight:'bold',textAlign:'left'}}>
          Join the forefront of online education innovation with SpeedUp
          Infotech, where we're reshaping the future of tech careers through
          immersive learning experiences.
        </p>
      </div>
      <div className="transform-img">
        <img src={photo} alt="" />
      </div>
    </div>
  );
};
