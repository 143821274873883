import React from "react";
import JobRoleCard from "./UI/JobRoleCard";
import "../Css/WallOfLove.css";
import male from "../almabetter resoures/Male.png";
import female from "../almabetter resoures/Female.png";

const WallOfLove = () => {
  return (
    <div className="wall-main-container">
      <h1 style={{ paddingLeft: "25px" }}>Wall of Love</h1>
      <div className="wall-container">
        <JobRoleCard
          employeeImg={male}
          employeeName={"Pratik Sable"}
          employeeRole={"Software Trainer"}
          feedbackContent={
            "Speedup Infotech has provided me with a platform to make a meaningful impact as a MERN Stack trainer. Here, I've found an environment that fosters continuous learning and growth, allowing me to stay at the forefront of technological advancements. The collaborative culture encourages enriching both the teaching and learning experience. As a MERN Stack trainer, guiding and mentoring aspiring developers has been a gratifying journey that fuels my passion for education and technology."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Shivani Waghmare"}
          employeeRole={"Software Trainer"}
          feedbackContent={
            "Joining Speedup Infotech as a Node.js trainer has been a rewarding journey, filled with opportunities for growth. Here, I've found a supportive community that values innovation and excellence in tech education. With access to exceptional resources and support, I've conducted dynamic training sessions, empowering students with the expertise needed to succeed in the ever-evolving tech landscape. It's been a privilege to shape the next generation of Node.js developers and witness their progress firsthand."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Pooja Lende"}
          employeeRole={"Senior BDE"}
          feedbackContent={
            "Speedup Infotech has provided me with a platform to make a meaningful impact as a MERN Stack trainer. Here, I've found an environment that fosters continuous learning and growth, allowing me to stay at the forefront of technological advancements. The collaborative culture encourages enriching both the teaching and learning experience. As a MERN Stack trainer, guiding and mentoring aspiring developers has been a gratifying journey that fuels my passion for education and technology."
          }
        />
      </div>
      <div className="wall-container">
        <JobRoleCard
          employeeImg={female}
          employeeName={"Shilpi Singh"}
          employeeRole={"HR Executive"}
          feedbackContent={
            "In my role as an HR Executive at Speedup Infotech, I've had the privilege of contributing to a culture that prioritizes employee well-being and professional growth. Speedup Infotech's commitment to fostering a supportive and inclusive environment has made it an exceptional workplace. I'm proud to be part of a team that values its employees and invests in their success."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Gauri Kutwal"}
          employeeRole={"BDE"}
          feedbackContent={
            "Joining Speedup Infotech as a Business Development Executive has been an incredible opportunity for me to grow both personally and professionally. The company's commitment to excellence and innovation is evident in everything we do, and I'm proud to be part of a team that is driving change in the tech industry. From collaborating with talented colleagues to working on impactful projects, every day presents new opportunities for growth and learning. I'm excited to see where this journey takes me and to continue making a positive impact at Speedup Infotech."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Yashashri Thorat"}
          employeeRole={"BDE"}
          feedbackContent={
            "In my role as a Business Development Executive at Speedup Infotech, I've had the privilege of being part of a team that is at the forefront of technological innovation. The company's dedication to excellence is evident in every project we undertake, and I'm constantly inspired by the passion and expertise of my colleagues. Working here has not only expanded my skill set but also provided me with invaluableopportunities for personal and professional growth."
          }
        />
      </div>
      <div className="wall-container">
        <JobRoleCard
          employeeImg={female}
          employeeName={"Pooja Savarkar"}
          employeeRole={"BDE"}
          feedbackContent={
            "Joining Speedup Infotech as a Business Development Executive has been an incredible opportunity for me to grow both personally and professionally. The company's commitment to excellence and innovation is evident in everything we do, and I'm proud to be part of a team that is driving change in the tech industry. From collaborating with talented colleagues to working on impactful projects, every day presents new opportunities for growth and learning. I'm excited to see where this journey takes me and to continue making a positive impact at Speedup Infotech."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Prajakta Mhade"}
          employeeRole={"BDM"}
          feedbackContent={
            "My experience as a Business Development Maneger at Speedup Infotech has been nothing short of extraordinary. From the moment I joined, I was impressed by the company's commitment to fostering talent and providing opportunities for growth. The collaborative culture, coupled with the challenging yet rewarding nature of the work, has enabled me to thrive in my role and expand my skill set. I'm grateful for the support and mentorship I've received, and I'm excited to continue my journey with Speedup Infotech."
          }
        />
        <JobRoleCard
          employeeImg={female}
          employeeName={"Prajakta Kadam"}
          employeeRole={"Accountant"}
          feedbackContent={
            "Speedup Infotech has provided me with a platform to make a meaningful impact as a MERN Stack trainer. Here, I've found an environment that fosters continuous learning and growth, allowing me to stay at the forefront of technological advancements. The collaborative culture encourages enriching both the teaching and learning experience. As a MERN Stack trainer, guiding and mentoring aspiring developers has been a gratifying journey that fuels my passion for education and technology."
          }
        />
      </div>
    </div>
  );
};

export default WallOfLove;
